import * as React from "react"
import { useMemo } from 'react'

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useLocation } from "@reach/router"
import { useRenkApiUri } from "src/hooks"

import * as styles from "src/scss/_form.module.scss"

export default function JobApplicationPage () {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { register, handleSubmit, watch, formState: { errors } } = useForm(
    {
      reValidateMode: 'onSubmit',
    }
  );

  const { hostname } = useLocation()
  const apiURI = useRenkApiUri(hostname)
  const GATEWAY_URL = useMemo(() => `${apiURI}recruit_form`, [apiURI])

  const onSubmit = async (data) => { // フォームを送信する処理
    const submitBtn = document.getElementById("submitBtn")
    submitBtn.disabled = true;
    document.getElementById("loading").style.display = "block"; // 送信中のくるくる表示

    const reCaptchaToken = await executeRecaptcha('JobApplicationPage'); // token取得

    try {
      const result = await fetch(GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify({
          token: reCaptchaToken,
          data: data
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const recaptchaResult = await result.json();
      if (recaptchaResult) {
        window.location.href = '/form-confirmation/';
        document.getElementById("loading").style.display = "none";
        submitBtn.disabled = false;
      } else {
        alert("応募の送信に失敗しました。お手数をおかけしますが、間をおいてもう一度お試しください。")
        document.getElementById("loading").style.display = "none";
        submitBtn.disabled = false;
      }
    } catch (error) {
      alert("応募の送信に失敗しました。お手数をおかけしますが、間をおいてもう一度お試しください。")
      document.getElementById("loading").style.display = "none";
      submitBtn.disabled = false;
    }
  };

  const emailValue = watch("email") // メールアドレス監視
  if (emailValue !== undefined) { // メールを全角から半角に変更
    let emailReplace = null
    emailReplace = emailValue.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    document.getElementById('email').value = emailReplace
  }

  const telValue = watch("tel") // 電話番号監視
  if (telValue !== undefined) { // 電話番号を全角から半角に変更
    let telReplace = null
    const hyphen = telValue.replace(/[‐－―ー]/g, ''); // 全角ハイフンをとる
    const hyphen2 = hyphen.replace(/[\-\x20]/g, ''); // 半角ハイフン、スペースをとる
    telReplace = hyphen2.replace(/[０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    document.getElementById('tel').value = telReplace
  }

  return (
    <Layout>
      <Seo title="応募フォーム" />
      <div className="pageWrapper">
        <h1 className="pageTtl">応募フォーム</h1>
        <p>応募フォームよりエントリーをお願いします。</p>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
          <div className={styles.textLabel}>
            <label htmlFor="name">お名前<span>必須</span></label>
            <p className={styles.inputEx}>例：山田 太郎</p>
          </div>
          <input className={styles.textInput} type="text" placeholder="例：山田 太郎" name="name" id="name" {...register('name', { required: true, maxLength: 30 })} />
            <p className={styles.errMsg}>
              {errors.name && errors.name.type === "required" &&"名前をご入力ください"}
              {errors.name && errors.name.type === "maxLength" &&"名前が長すぎます"}
            </p>

          <div className={styles.textLabel}>
            <label htmlFor="nameKana">お名前（カタカナ）<span>必須</span></label>
            <p className={styles.inputEx}>例：ヤマダ タロウ</p>
          </div>
          <input className={styles.textInput} type="text" placeholder="例：ヤマダ タロウ" name="nameKana" id="nameKana" {...register('nameKana', { required: true, maxLength: 30 })} />
          <p className={styles.errMsg}>
            {errors.nameKana && errors.nameKana.type === "required" &&"フリガナをご入力ください"}
            {errors.nameKana && errors.nameKana.type === "maxLength" &&"フリガナが長すぎます"}
          </p>

          <p className={styles.textLabel}>性別<span>必須</span></p>
          <div className={styles.gender}>
            <label>
              女性
              <input type="radio" value="女" name="gender" id="female" {...register('gender', { required: true })}/>
            </label>
            <label>
              男性
              <input type="radio" value="男性" name="gender" id="male" {...register('gender', { required: true })}/>
            </label>
            <label>
              その他
              <input type="radio" value="その他" name="gender" id="other" {...register('gender', { required: true })}/>
            </label>
            <label>
              無回答
              <input type="radio" value="無回答" name="gender" id="none" {...register('gender', { required: true })}/>
            </label>
          </div>
          <p className={styles.errMsg}>
            {errors.gender && errors.gender.type === "required" &&"一つお選びください"}
          </p>

          <div className={styles.textLabel}>
            <label htmlFor="email">メールアドレス<span>必須</span></label>
            <p className={styles.inputEx}>例：yamada@email.jp 半角</p>
          </div>
          <input className={styles.textInput} type="email" placeholder="例：yamada@email.jp" name="email" id="email" {...register('email', { required: true, pattern: /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/ })} />
          <p className={styles.errMsg}>
            {errors.email && errors.email.type === "required" &&"メールアドレスをご入力ください"}
            {errors.email && errors.email.type === "pattern" &&"正しい形でご入力ください"}
          </p>

          <div className={styles.textLabel}>
            <label htmlFor="tel">お電話番号<span>必須</span></label>
            <p className={styles.inputEx}>例：0251234567  半角、ハイフン(-)なし</p>
          </div>
          <input className={styles.textInput} type="tel" placeholder="例：0251234567（ハイフン－なし）" name="tel" id="tel" {...register('tel', { required: true, pattern: /^(0{1}\d{9,10})$/ })} />
          <p className={styles.errMsg}>
            {errors.tel && errors.tel.type === "required" &&"電話番号をご入力ください"}
            {errors.tel && errors.tel.type === "pattern" &&"電話番号が正しくありません"}
          </p>

          <div className={styles.textLabel}>
            <label>希望職種</label>
          </div>
          <select name="position" className={styles.selectBox}  {...register('position')}>
            <option value="">ご希望の職種がある場合はお選びください</option>
            <option value="サーバーサイドエンジニア">サーバーサイドエンジニア</option>
            <option value="アプリエンジニア（Android／iOS）">アプリエンジニア（Android／iOS）</option>
            <option value="ディレクター">ディレクター</option>
            <option value="Windowsアプリケーションエンジニア">Windowsアプリケーションエンジニア</option>
            <option value="フロントエンドエンジニア">フロントエンドエンジニア</option>
            <option value="インフラエンジニア">インフラエンジニア</option>
            <option value="ヘルプデスク">ヘルプデスク</option>
          </select>

          <div className={styles.textLabel}>
            <label htmlFor="message1">弊社を知った、または 応募したきっかけはなんですか？ （150文字）</label>
          </div>
          <textarea name="message1" id="message1" rows="5" {...register('message1')} />

          <div className={styles.textLabel}>
            <label htmlFor="message2">その他ご質問などございましたら ご記入ください （150文字）</label>
          </div>
          <textarea name="message2" id="message2" rows="5" {...register('message2')} />

          <div className={styles.textLabel}>
            <label>個人情報の取り扱いについて</label>
          </div>
          <div className="policy">
            <p>プライバシーポリシー 【基本方針】</p>
            <p>株式会社ハードオフコーポレーションおよび子会社（以下「当社グループ」）は、お客様、お取引様、
              株主様ならびに当社グループ役員および社員の個人情報保護の重要性を認識し、
              法令遵守および安全に取扱いするため、以下のとおり取り組んでまいります。</p>
            <p>【個人情報保護に関する事項】</p>
            <ol>
              <li className="listTtl">法令等の遵守</li>
              <p>
                当社グループは、個人情報を取扱うにあたり、個人情報保護法その他関係法令および 業界ガイドライン、社内規程等を遵守いたします。
              </p>

              <li className="listTtl">個人情報の利用について</li>
              <p>当社グループにおける個人情報は、個人情報の提供を受ける際にお知らせする利用目的の範囲内で利用します。</p>

              <li className="listTtl">個人情報の安全管理について</li>
              <p>当社グループは、個人情報を適正に管理・運用するために個人情報保護規程および特定個人情報取扱規程を定め、
                すべての役員・社員に対し、個人情報の安全管理が図られるよう適切な監督を行います。<br />
                また、個人情報を委託する場合、個人情報の安全管理が図られるよう委託先に対し必要かつ適切な監督を行います。
              </p>

              <li className="listTtl">個人情報の第三者への提供について</li>
              <p>当社グループは、次の場合を除き個人情報を第三者へ開示・提供いたしません。</p>
              <ol>
                <li>本人の同意がある場合</li>
                <li>法令に基づく場合</li>
                <li>人の生命、身体または財産の保護のために第三者提供が必要な場合で、本人の同意を得ることが困難なとき</li>
                <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要な場合で、本人の同意を得ることが困難なとき</li>
                <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、
                  本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                </li>
              </ol>

              <li className="listTtl">共同利用について</li>
              <p>当社グル―プは、個人情報を共同利用する場合があります。</p>
              <ol>
                <li>共同して利用する個人データの項目 氏名、住所、年齢、生年月日、職業、電話番号、商品売買情報等</li>
                <li>共同して利用する者の範囲 当社グループ各社およびフランチャイズ加盟店</li>
                <li>利用する者の利用目的 個人情報の提供を受ける際にお知らせする利用目的</li>
                <li>当該個人データの管理について責任を有する者の名称 株式会社ハードオフコーポレーション</li>
              </ol>

              <li className="listTtl">個人情報の開示・訂正・利用停止について</li>
              <p>当社グループは、保有している個人情報の開示・訂正・利用停止に関するお問い合わせについて合理的な範囲で速やかに対応いたします
                （なお一定の制約をともなう場合や請求者等の費用負担が必要になる場合があります）。
              </p>
            </ol>
          </div>

          <div className={styles.agreement}>
            <input className={styles.textInput} type="checkbox" name="privacy" id="privacy" {...register('privacy', { required: true })} />
            <label htmlFor="privacy">個人情報保護方針に同意する</label>
            <p className={styles.errMsg2}>
              {errors.privacy&&"個人情報保護方針にご同意ください。同意するにはチェックを入れます。"}
            </p>
          </div>

          <div className="btnWrapper">
            <div id="loading">
              <div className="loader"></div>
              <p>送信中</p>
            </div>
            <button className="btnShort" type="submit" id="submitBtn">入力内容を送信する</button>
          </div>
        </form>
        <div className="recaptcha">
          This site is protected by reCAPTCHA and the Google <br />
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and
          <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer"> Terms of Service</a> apply.
        </div>
      </div>
    </Layout>
  )
}
